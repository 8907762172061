
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Inicio",
    "icon": "pi pi-chart-bar",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/articulo",
    "label": "Articulos",
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/galerias",
    "label": "Galerias",
    "icon": "pi pi-images",
    "iconcolor": "",
    "target": "",
    
  }
],
	categoria: [    
{value: "NOTICIA", label: "NOTICIA"},
	{value: "ARTICULOS", label: "ARTICULOS"},
	{value: "ARTICULO-ESR", label: "ARTICULO-ESR"}
    ],
	descripcionEn: [    
{value: "2", label: "NO"},
	{value: "1", label: "SI"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}