
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//articulo routes
			{
				path: '/articulo/:fieldName?/:fieldValue?',
				name: 'articulolist',
				component: () => import('./pages/articulo/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/articulo/view/:id', 
				name: 'articuloview', 
				component: () => import('./pages/articulo/view.vue'), 
				props: true
			},
		
			{ 
				path: '/articulo/add', 
				name: 'articuloadd', 
				component: () => import('./pages/articulo/add.vue'), 
				props: true
			},
	
			{ 
				path: '/articulo/edit/:id', 
				name: 'articuloedit', 
				component: () => import('./pages/articulo/edit.vue'), 
				props: true
			},
		

//contacto routes
			{
				path: '/contacto/:fieldName?/:fieldValue?',
				name: 'contactolist',
				component: () => import('./pages/contacto/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/contacto/view/:id', 
				name: 'contactoview', 
				component: () => import('./pages/contacto/view.vue'), 
				props: true
			},
		
			{ 
				path: '/contacto/add', 
				name: 'contactoadd', 
				component: () => import('./pages/contacto/add.vue'), 
				props: true
			},
	
			{ 
				path: '/contacto/edit/:id', 
				name: 'contactoedit', 
				component: () => import('./pages/contacto/edit.vue'), 
				props: true
			},
		

//empresas routes
			{
				path: '/empresas/:fieldName?/:fieldValue?',
				name: 'empresaslist',
				component: () => import('./pages/empresas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/empresas/view/:id', 
				name: 'empresasview', 
				component: () => import('./pages/empresas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/empresas/add', 
				name: 'empresasadd', 
				component: () => import('./pages/empresas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/empresas/edit/:id', 
				name: 'empresasedit', 
				component: () => import('./pages/empresas/edit.vue'), 
				props: true
			},
		

//galeria routes
			{
				path: '/galeria/:fieldName?/:fieldValue?',
				name: 'galerialist',
				component: () => import('./pages/galeria/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/galeria/view/:id', 
				name: 'galeriaview', 
				component: () => import('./pages/galeria/view.vue'), 
				props: true
			},
		
			{ 
				path: '/galeria/add', 
				name: 'galeriaadd', 
				component: () => import('./pages/galeria/add.vue'), 
				props: true
			},
	
			{ 
				path: '/galeria/edit/:id', 
				name: 'galeriaedit', 
				component: () => import('./pages/galeria/edit.vue'), 
				props: true
			},
		

//galerias routes
			{
				path: '/galerias/:fieldName?/:fieldValue?',
				name: 'galeriaslist',
				component: () => import('./pages/galerias/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/galerias/view/:id', 
				name: 'galeriasview', 
				component: () => import('./pages/galerias/view.vue'), 
				props: true
			},
		
			{ 
				path: '/galerias/add', 
				name: 'galeriasadd', 
				component: () => import('./pages/galerias/add.vue'), 
				props: true
			},
	
			{ 
				path: '/galerias/edit/:id', 
				name: 'galeriasedit', 
				component: () => import('./pages/galerias/edit.vue'), 
				props: true
			},
		

//membresia routes
			{
				path: '/membresia/:fieldName?/:fieldValue?',
				name: 'membresialist',
				component: () => import('./pages/membresia/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/membresia/view/:id', 
				name: 'membresiaview', 
				component: () => import('./pages/membresia/view.vue'), 
				props: true
			},
		
			{ 
				path: '/membresia/add', 
				name: 'membresiaadd', 
				component: () => import('./pages/membresia/add.vue'), 
				props: true
			},
	
			{ 
				path: '/membresia/edit/:id', 
				name: 'membresiaedit', 
				component: () => import('./pages/membresia/edit.vue'), 
				props: true
			},
		

//nomembresia routes
			{
				path: '/nomembresia/:fieldName?/:fieldValue?',
				name: 'nomembresialist',
				component: () => import('./pages/nomembresia/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/nomembresia/view/:id', 
				name: 'nomembresiaview', 
				component: () => import('./pages/nomembresia/view.vue'), 
				props: true
			},
		
			{ 
				path: '/nomembresia/add', 
				name: 'nomembresiaadd', 
				component: () => import('./pages/nomembresia/add.vue'), 
				props: true
			},
	
			{ 
				path: '/nomembresia/edit/:id', 
				name: 'nomembresiaedit', 
				component: () => import('./pages/nomembresia/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//tipo routes
			{
				path: '/tipo/:fieldName?/:fieldValue?',
				name: 'tipolist',
				component: () => import('./pages/tipo/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipo/view/:id', 
				name: 'tipoview', 
				component: () => import('./pages/tipo/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipo/add', 
				name: 'tipoadd', 
				component: () => import('./pages/tipo/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipo/edit/:id', 
				name: 'tipoedit', 
				component: () => import('./pages/tipo/edit.vue'), 
				props: true
			},
		

//usuario routes
			{
				path: '/usuario/:fieldName?/:fieldValue?',
				name: 'usuariolist',
				component: () => import('./pages/usuario/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/usuario/view/:id', 
				name: 'usuarioview', 
				component: () => import('./pages/usuario/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'usuarioaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usuarioaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/usuario/add', 
				name: 'usuarioadd', 
				component: () => import('./pages/usuario/add.vue'), 
				props: true
			},
	
			{ 
				path: '/usuario/edit/:id', 
				name: 'usuarioedit', 
				component: () => import('./pages/usuario/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
